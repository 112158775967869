import "./reset.css"
import "./grid.css"
import "./flow.css"
import "./functional.css"
import "./icons.css"

import "./static/assets/fonts/fonts.css"
import "./vars.css"
import "./body.css"
import "./admin.css"

import "./buttons.css"
import "./cells.css"
import "./details.css"
import "./embeds.css"
import "./forms.css"
// import "./messages.css"
// import "./pagination.css"
import "./prose.css"
import "./station.css"

// import { setDetailsHeights } from "./details.js"
import { renderStation } from "./station.js"
import { onReady, qs, qsa } from "./utils.js"

// onReady(initForms)

onReady(() => {
  qsa("[data-station]").forEach(renderStation)
})

/*
onReady(() => {
  setDetailsHeights(".details")
})
*/

onReady(() => {
  const toggle = qs("#sl-toggle"),
    eh = qs("#expand-menu")
  if (toggle && eh) {
    toggle.addEventListener("click", () => {
      eh.checked = !eh.checked
    })
  }
})
