import { render } from "preact"
import { useCallback, useEffect, useRef, useState } from "preact/hooks"
import { ReactSortable } from "react-sortablejs"
import { useIdleTimer } from "react-idle-timer"

// import { fetchJSON } from "./fetch.js"
import { qs } from "./utils.js"

/*
const calculateSums = (comics, ratings) => {
  const sums = {}
  for (let comic of comics) {
    sums[comic.type] = (sums[comic.type] || 0) + ratings.get(comic.id)
  }
  return sums
}

const calculateOrderedTypes = (types, comics, ratings) => {
  const sums = calculateSums(comics, ratings)
  const r = types.map((type) => ({ ...type, score: sums[type.type] || 0 }))
  r.sort((a, b) => b.score - a.score)
  return r
}
*/

const Screen = ({ modifier = "", children }) => (
  <div class="station">
    <div class={`station station--${modifier}`}>{children}</div>
  </div>
)

const Explanation = ({ advance }) => (
  <Screen modifier="explanation">
    <div class="flow">
      <h1>Deine persönlichen Lebensvorstellungen</h1>
      <p>
        Herzlich Willkommen zum Tool über deine persönlichen
        Lebensvorstellungen. Im Folgenden wirst du gebeten, 12&nbsp;Bilder, die
        verschiedene Aspekte des Lebens betonen, prioritär zu ordnen.
      </p>
    </div>
    <button class="button button--continue" onClick={advance}>
      Weiter
    </button>
  </Screen>
)

const motiveScores = (images) => {
  const scores = {},
    firstScore = images.length
  images.forEach(
    ({ motiveId }, idx) =>
      (scores[motiveId] = (scores[motiveId] || 0) + (firstScore - idx)),
  )
  return scores
}

const sortedMotives = (images, motives) => {
  const scores = motiveScores(images)
  motives = motives.map((m) => ({ ...m, score: scores[m.id] }))
  return motives.sort((a, b) => {
    // Sort by score first, then by their ordering in the CMS
    if (a.score !== b.score) {
      // Higher scores first
      return b.score - a.score
    }
    return a.ordering - b.ordering
  })
}

// eslint-disable-next-line no-unused-vars
const Game = ({ images, motives, setImages, advance }) => {
  const scrollRef = useRef()

  useEffect(() => {
    const listener = () => {
      scrollRef.current.style.transform = `rotate(${
        window.scrollY < 50 ? 90 : -90
      }deg)`
    }

    listener()
    window.addEventListener("scroll", listener, { passive: true })
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })

  const handleScrollClick = () => {
    window.scrollTo({
      top: window.scrollY < 50 ? 10000 : 0,
      behavior: "smooth",
    })
  }

  return (
    <Screen modifier="game">
      <p>
        Ordne die folgenden 12 Bilder danach, inwieweit die gezeigten Inhalte
        wichtige Bestandteile deines Lebens sein sollten. Dies ist unabhängig
        davon, ob diese in deinem realen Leben momentan auch wichtige
        Bestandteile sind. Zudem geht es weniger darum, was die Bilder ganz
        konkret zeigen, sondern vielmehr, für was sie in deinen Augen stehen.
        Das Bild, das den für dich wichtigsten Bestandteil zeigt, solltest du
        ganz nach oben ziehen
        <br />
        (per Drag'n'Drop).
      </p>
      {/*<p>{sortedMotives(images, motives).map((m) => `${m.title} (${m.score})`).join(", ")}</p>*/}
      <ReactSortable
        list={images}
        setList={setImages}
        scrollSensitivity={50}
        scrollSpeed={30}
        className="images"
      >
        {images.map((image, idx) => (
          <div
            class="images__image"
            style={{
              backgroundImage: `url(${image.image})`,
            }}
            key={image.id}
          >
            <h3>{idx + 1}.</h3>
          </div>
        ))}
      </ReactSortable>
      <button class="button button--continue" onClick={advance}>
        Weiter
      </button>
      <button
        class="button button--scroll"
        onClick={handleScrollClick}
        ref={scrollRef}
      >
        {arrow}
      </button>
    </Screen>
  )
}

const communalMotivesStronger = (motives) => {
  const score = {}
  for (let motive of motives) {
    score[motive.type] = (score[motive.type] || 0) + motive.score
  }
  return score["Kommunal"] >= score["Agentisch"]
}

const Evaluation = ({ images, motives, doReset }) => {
  const m = sortedMotives(images, motives)
  return (
    <Screen modifier="evaluation">
      <div class="flow">
        <h1>Dein Ergebnis</h1>
        <p>
          In der Psychologie wird der Begriff des Motivs verwendet, um zu
          beschreiben, dass sich Personen darin unterscheiden, wie stark eine
          Situation die eigene (Handlungs-)Motivation anregt. Grundlegende
          Motive sind das Leistungsmotiv, das Machtmotiv, das Anschlussmotiv und
          das Intimitätsmotiv.
        </p>
        <p>
          Die von dir gewählte Ordnung der Bilder spricht für folgende
          prioritäre Reihenfolge deiner Motive. Das bei dir am stärksten
          ausgeprägte Motiv steht ganz oben.
        </p>
        {m.map((motive) => (
          <div class="motive flow" key={motive.id}>
            <h2>{motive.title}</h2>
            <p>{motive.description}</p>
          </div>
        ))}
        <h2>Zum Schluss</h2>
        <p>
          Das Leistungsmotiv und das Machtmotiv werden zu Kontrollmotiven
          zusammengefasst und das Anschlussmotiv und das Intimitätsmotiv werden
          auch als Gemeinschaftsmotive bezeichnet. Deine Bildauswahl spricht
          dafür, dass du den Gemeinschaftsmotiven eine vergleichsweise
          {communalMotivesStronger(m) ? " stärkere " : " schwächere "}
          Bedeutung zumisst.
        </p>
        <p>
          Überlege dir, ob du einverstanden bist mit der für dich ermittelten
          Reihenfolge der vier grundlegenden Motive (und falls nicht, warum).
          Denke abschliessend darüber nach, inwieweit deine tatsächlichen
          Prioritäten im Einklang mit deinem aktuellen Leben stehen.
        </p>
        <h2>&nbsp;</h2>
        <p>
          <strong>Konzeptionelle Entwicklung dieses Tools</strong>
          <br />
          Dr. Michael Dantlgraber
          <br />
          Forschungsteam der Kalaidos Fachhochschule
        </p>
        <button class="button button--continue" onClick={doReset}>
          Zurück zur Startseite
        </button>
      </div>
    </Screen>
  )
}

const Round = (props) => {
  const [current, setCurrent] = useState(0)

  const advance = () => setCurrent((c) => c + 1)
  const [images, setImages] = useState(props.images)

  const components = [Explanation, Game, Evaluation]
  const Component = components[current]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [current])

  return (
    <Component
      {...props}
      advance={advance}
      images={images}
      setImages={setImages}
    />
  )
}

const Station = (props) => {
  const doReset = useCallback(() => {
    window.location.reload()
  }, [])

  useIdleTimer({
    timeout: 90 * 1000,
    onIdle: doReset,
  })
  return <Round {...props} doReset={doReset} />
}

export function renderStation(el) {
  const props = JSON.parse(qs("script", el).textContent)

  render(<Station {...props} />, el)
}

const arrow = (
  <svg id="icon-arrow" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z" />
  </svg>
)
