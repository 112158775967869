export function onReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export function qs(selector, base = document) {
  return base.querySelector(selector)
}

export function qsa(selector, base = document) {
  return base.querySelectorAll(selector)
}

/*
export const { gettext, pgettext, ngettext } = window
// Make interpolate() always use named placeholders
const i = window.interpolate
export const interpolate = (s, p) => i(s, p, true)
*/
